import React, { useRef, useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { useMutation, gql } from '@apollo/client'
import { validateFunc } from '../../../constraints/constraints'
import { saveAppVersionConfiguration } from '../../../apollo'
import useStyles from '../styles'
import useGlobalStyles from '../../../utils/globalStyles'
import { Box, Typography, Input, Button, Alert } from '@mui/material'

const SAVE_APP_VERSION_CONFIGURATION = gql`
  ${saveAppVersionConfiguration}
`

function AppVersionConfiguration(props) {
  const { t } = props
  const formRef = useRef()
  const iosMinBuildVersion = props.iosMinBuildVersion || 1
  const androidMinBuildVersion = props.androidMinBuildVersion || 1
  const [iosMinBuildVersionError, setIosMinBuildVersionError] = useState(null)
  const [androidMinBuildVersionError, setAndroidMinBuildVersionError] = useState(null)

  const onBlur = (setter, field, state) => {
    setter(!validateFunc({ [field]: state }, field))
  }
  const [mutate, { loading }] = useMutation(SAVE_APP_VERSION_CONFIGURATION)

  const validateInput = () => {
    let iosMinVersionResult = true
    let androidMinVersionResult = true
    iosMinVersionResult = !!formRef.current['input-iosMinVersion'].value
    androidMinVersionResult = !!formRef.current['input-androidMinVersion'].value
    setIosMinBuildVersionError(iosMinVersionResult)
    setAndroidMinBuildVersionError(androidMinVersionResult)
    return iosMinVersionResult && androidMinVersionResult
  }

  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const [successMessage, setSuccessMessage] = useState('')
  const handleSuccess = message => {
    setSuccessMessage(message)
  }
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSuccessMessage('')
    }, 3000)

    return () => clearTimeout(timeoutId)
  }, [successMessage, setSuccessMessage])
  const [errorMessage, setErrorMessage] = useState('')
  const handleError = error => {
    setErrorMessage('An error occurred while saving configuration.')
    console.error('Mutation error:', error)
  }
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setErrorMessage('')
    }, 3000)

    return () => clearTimeout(timeoutId)
  }, [errorMessage, setErrorMessage])

  return (
    <Box container className={classes.container}>
      <Box className={classes.flexRow}>
        <Box item className={classes.heading}>
          <Typography variant="h6" className={classes.text}>
            {t('AppVersion')}
          </Typography>
        </Box>
      </Box>

      <Box className={classes.form}>
        <form ref={formRef}>
          <Box>
            <Typography className={classes.labelText}>
              {t('iOSMinVersion')}
            </Typography>
            <Input
              style={{ marginTop: -1 }}
              id="input-iosMinVersion"
              name="input-iosMinVersion"
              placeholder="1"
              defaultValue={iosMinBuildVersion}
              onBlur={event => {
                onBlur(
                    setIosMinBuildVersionError,
                    'appMinimumVersion',
                    event.target.value.trim()
                )
              }}
              disableUnderline
              className={[
                globalClasses.input,
                iosMinBuildVersionError === false
                  ? globalClasses.inputError
                  : iosMinBuildVersionError === true
                  ? globalClasses.inputSuccess
                  : ''
              ]}
            />
          </Box>
          <Box>
            <Typography className={classes.labelText}>
              {t('androidMinVersion')}
            </Typography>
            <Input
              style={{ marginTop: -1 }}
              id="input-androidMinVersion"
              name="input-androidMinVersion"
              placeholder="1"
              defaultValue={androidMinBuildVersion}
              onBlur={event => {
                onBlur(
                    setAndroidMinBuildVersionError,
                    'appMinimumVersion',
                    event.target.value.trim()
                )
              }}
              disableUnderline
              className={[
                globalClasses.input,
                androidMinBuildVersionError === false
                  ? globalClasses.inputError
                  : androidMinBuildVersionError === true
                  ? globalClasses.inputSuccess
                  : ''
              ]}
            />
          </Box>
          <Box>
            <Button
              className={globalClasses.button}
              disabled={loading}
              onClick={e => {
                e.preventDefault()
                if (validateInput() && !loading) {
                  mutate({
                    variables: {
                      configurationInput: {
                        iosMinBuildVersion: parseInt(formRef.current['input-iosMinVersion'].value),
                        androidMinBuildVersion: parseInt(formRef.current['input-androidMinVersion'].value)
                      }
                    },
                    onCompleted: data => {
                      handleSuccess('Configuration saved successfully!')
                    },
                    onError: error => {
                      handleError(error)
                    }
                  })
                }
              }}>
              {t('Save')}
            </Button>
          </Box>
          <Box mt={2}>
            {successMessage && (
              <Alert
                className={globalClasses.alertSuccess}
                variant="filled"
                severity="success">
                {successMessage}
              </Alert>
            )}
            {errorMessage && (
              <Alert
                className={globalClasses.alertError}
                variant="filled"
                severity="error">
                {errorMessage}
              </Alert>
            )}
          </Box>
        </form>
      </Box>
    </Box>
  )
}
export default withTranslation()(AppVersionConfiguration)
