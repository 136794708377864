// GoogleApiKeyConfiguration.jsx

import React, { useRef, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useMutation, gql } from '@apollo/client'
import { validateFunc } from '../../../constraints/constraints'
import { saveGoogleApiKeyConfiguration } from '../../../apollo'
import useStyles from '../styles'
import useGlobalStyles from '../../../utils/globalStyles'
import { Box, Typography, Input, Button } from '@mui/material'

const SAVE_GOOGLE_API_KEY_CONFIGURATION = gql`
  ${saveGoogleApiKeyConfiguration}
`

function GoogleApiKeyConfiguration(props) {
  const formRef = useRef()

  const [webGoogleApiKey] = useState(props.webGoogleApiKey || '')
  const [iosGoogleApiKey] = useState(props.iosGoogleApiKey || '')
  const [androidGoogleApiKey] = useState(props.androidGoogleApiKey || '')

  const [webGoogleApiKeyError, setWebGoogleApiKeyError] = useState(null)
  const [iosGoogleApiKeyError, setiOSGoogleApiKeyError] = useState(null)
  const [androidGoogleApiKeyError, setAndroidGoogleApiKeyError] = useState(null)

  const [mutate, { loading }] = useMutation(SAVE_GOOGLE_API_KEY_CONFIGURATION)

  const onBlur = (setter, field, state) => {
    setter(!validateFunc({ [field]: state }, field))
  }

  const validateInput = () => {
    if(validateFunc(
      { googleApiKey: formRef.current['input-webGoogleApiKey'].value },
      'googleApiKey'
    )) {
      setWebGoogleApiKeyError(false)
      return false
    }

    if(validateFunc(
      { googleApiKey: formRef.current['input-iosGoogleApiKey'].value },
      'googleApiKey'
    )) {
      setiOSGoogleApiKeyError(false)
      return false
    }

    if(validateFunc(
      { googleApiKey: formRef.current['input-androidGoogleApiKey'].value },
      'googleApiKey'
    )) {
      setAndroidGoogleApiKeyError(false)
      return false
    }

    return true
  }

  const classes = useStyles()
  const globalClasses = useGlobalStyles()

  return (
    <Box container className={classes.container}>
      <Box className={classes.flexRow}>
        <Box item className={classes.heading}>
          <Typography variant="h6" className={classes.text}>
            Google API Key
          </Typography>
        </Box>
      </Box>

      <Box className={classes.form}>
        <form ref={formRef}>
          <Box>
            <Typography className={classes.labelText}>
              Web Google API Key
            </Typography>
            <Input
              style={{ marginTop: -1 }}
              id="input-webGoogleApiKey"
              name="input-webGoogleApiKey"
              placeholder="Web Google API Key"
              defaultValue={webGoogleApiKey}
              type="password"
              onBlur={event =>
                onBlur(setWebGoogleApiKeyError, 'googleApiKey', event.target.value)
              }
              disableUnderline
              className={[
                globalClasses.input,
                webGoogleApiKeyError === false
                  ? globalClasses.inputError
                  : webGoogleApiKeyError === true
                  ? globalClasses.inputSuccess
                  : ''
              ]}
            />
          </Box>
          <Box>
            <Typography className={classes.labelText}>
              iOS Google API Key
            </Typography>
            <Input
              style={{ marginTop: -1 }}
              id="input-iosGoogleApiKey"
              name="input-iosGoogleApiKey"
              placeholder="iOS Google API Key"
              defaultValue={iosGoogleApiKey}
              type="password"
              onBlur={event =>
                onBlur(setiOSGoogleApiKeyError, 'googleApiKey', event.target.value)
              }
              disableUnderline
              className={[
                globalClasses.input,
                iosGoogleApiKeyError === false
                  ? globalClasses.inputError
                  : iosGoogleApiKeyError === true
                  ? globalClasses.inputSuccess
                  : ''
              ]}
            />
          </Box>
          <Box>
            <Typography className={classes.labelText}>
              Android Google API Key
            </Typography>
            <Input
              style={{ marginTop: -1 }}
              id="input-androidGoogleApiKey"
              name="input-androidGoogleApiKey"
              placeholder="Android Google API Key"
              defaultValue={androidGoogleApiKey}
              type="password"
              onBlur={event =>
                onBlur(setAndroidGoogleApiKeyError, 'googleApiKey', event.target.value)
              }
              disableUnderline
              className={[
                globalClasses.input,
                androidGoogleApiKeyError === false
                  ? globalClasses.inputError
                  : androidGoogleApiKeyError === true
                  ? globalClasses.inputSuccess
                  : ''
              ]}
            />
          </Box>
          <Box>
            <Button
              className={globalClasses.button}
              disabled={loading}
              onClick={e => {
                e.preventDefault()
                if (validateInput() && !loading) {
                  mutate({
                    variables: {
                      configurationInput: {
                        webGoogleApiKey: formRef.current['input-webGoogleApiKey'].value,
                        iosGoogleApiKey: formRef.current['input-iosGoogleApiKey'].value,
                        androidGoogleApiKey: formRef.current['input-androidGoogleApiKey'].value,
                      }
                    }
                  })
                }
              }}>
              SAVE
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}

export default withTranslation()(GoogleApiKeyConfiguration)
